.mat-dialog-container {
  padding: 0px !important;
}

.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
  outline: 0;
}

.fade-modal {
  transition: opacity 0.15s linear;
  .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translateY(-25%);
  }
  .modal-sm {
    width: 711px;
    max-width: 711px !important;
    position: relative;
  }
  .max-w-360 {
    max-width: 360px !important;
  }
  .max-w-600 {
    max-width: 600px !important;
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    position: relative;
    background-color: var(--psr-font-color-primary);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    outline: 0;
    -o-animation-name: animatetop;
    -o-animation-duration: 0.4s;
    -moz-animation-name: animatetop;
    -moz-animation-duration: 0.4s;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    .modal-body {
      padding: 0;
    }
  }
}

.fade-modal.in-modal {
  display: block;
  .modal-dialog {
    transform: translate(0);
    margin: 30px auto;
  }
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

//
.modal-passer {
  ion-backdrop {
    background-color: var(--ion-color-primary) !important;
    opacity: 0.7 !important;
  }
}

.modal-passer-remind {
  --max-height: 100%;
  @media screen and (min-width: 480px) {
    --max-height: 320px;
  }
}

.modal-90 {
  --height: 100vh;
}

.modal-passer-terms,
.modal-passer-remind {
  ion-footer {
    justify-content: center;
    padding: 12px 16px;
    button {
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) {
  .modal-passer-terms,
  .modal-passer-remind {
    padding: 16px 30px;
    ion-footer {
      justify-content: end;
      padding: 12px 40px;
      button {
        min-width: 150px;
        width: auto;
      }
    }
  }
  .modal-90 {
    --height: 90vh;
  }
}

.title-default {
  padding: 0px;
}

ion-modal {
  --backdrop-opacity: 0.6;
}

.modal-fullscreen {
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.ios .modal-fullscreen {
  .modal-wrapper {
    top: 26px;
  }
}

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'src/theme/borders.scss';
@import 'src/theme/buttons.scss';
@import 'src/theme/colors.scss';
@import 'src/theme/container.scss';
@import 'src/theme/flex.scss';
@import 'src/theme/material-style.scss';
@import 'src/theme/modal.scss';
@import 'src/theme/spacing.scss';
@import 'src/theme/text.scss';
@import 'src/theme/variables.scss';
@import 'src/theme/grid.scss';
@import 'src/theme/radio.scss';

html {
  background-color: var(--psr-primary-background);
  font-size: 16px;
  min-height: 100vh;
  position: relative;
}

body {
  overflow-y: hidden;
  background-color: var(--psr-secondary-background);
  color: var(--psr-text-color) !important;
  font-family: Arial, sans-serif, 'Segoe UI', 'Roboto', 'Helvetica Neue' !important;
  font-size: 16px !important;
  min-height: 100vh !important;
  min-width: 100vw !important;
  line-height: 1.5 !important;
  position: fixed;
}

*:focus {
  outline: 0 !important;
}

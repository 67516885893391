$spacer: 1rem !default;
$spacers: (
  0: 0,
  2: $spacer * 0.125,
  4: $spacer * 0.25,
  6: $spacer * 0.375,
  8: $spacer * 0.5,
  10: $spacer * 0.625,
  12: $spacer * 0.75,
  14: $spacer * 0.875,
  16: $spacer,
  18: $spacer * 1.125,
  20: $spacer * 1.25,
  24: $spacer * 1.5,
  30: $spacer * 1.875,
  32: $spacer * 2,
  34: $spacer * 2.125,
  40: $spacer * 2.5,
  45: $spacer * 2.8125,
  48: $spacer * 3,
  56: $spacer * 3.5,
  60: $spacer * 3.75,
  64: $spacer * 4,
  70: $spacer * 4.375,
  100: $spacer * 6.25,
);

.m-0 {
  margin: 0 !important;
}
.m-2 {
  margin: map-get($spacers, 2) !important;
}
.m-4 {
  margin: map-get($spacers, 4) !important;
}
.m-6 {
  margin: map-get($spacers, 6) !important;
}
.m-8 {
  margin: map-get($spacers, 8) !important;
}
.m-10 {
  margin: map-get($spacers, 10) !important;
}
.m-12 {
  margin: map-get($spacers, 12) !important;
}
.m-14 {
  margin: map-get($spacers, 14) !important;
}
.m-16 {
  margin: map-get($spacers, 16) !important;
}
.m-18 {
  margin: map-get($spacers, 18) !important;
}
.m-20 {
  margin: map-get($spacers, 20) !important;
}
.m-24 {
  margin: map-get($spacers, 24) !important;
}
.m-30 {
  margin: map-get($spacers, 30) !important;
}
.m-32 {
  margin: map-get($spacers, 32) !important;
}
.m-34 {
  margin: map-get($spacers, 34) !important;
}
.m-40 {
  margin: map-get($spacers, 40) !important;
}
.m-45 {
  margin: map-get($spacers, 45) !important;
}
.m-48 {
  margin: map-get($spacers, 48) !important;
}
.m-56 {
  margin: map-get($spacers, 56) !important;
}
.m-60 {
  margin: map-get($spacers, 60) !important;
}
.m-64 {
  margin: map-get($spacers, 64) !important;
}
.m-70 {
  margin: map-get($spacers, 70) !important;
}
.m-100 {
  margin: map-get($spacers, 100) !important;
}

.m-auto {
  margin: 0 auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-2 {
  margin-top: map-get($spacers, 2) !important;
}
.mr-2 {
  margin-right: map-get($spacers, 2) !important;
}
.mb-2 {
  margin-bottom: map-get($spacers, 2) !important;
}
.ml-2 {
  margin-left: map-get($spacers, 2) !important;
}
.mx-2 {
  margin-right: map-get($spacers, 2) !important;
  margin-left: map-get($spacers, 2) !important;
}
.my-2 {
  margin-top: map-get($spacers, 2) !important;
  margin-bottom: map-get($spacers, 2) !important;
}

.mt-4 {
  margin-top: map-get($spacers, 4) !important;
}
.mr-4 {
  margin-right: map-get($spacers, 4) !important;
}
.mb-4 {
  margin-bottom: map-get($spacers, 4) !important;
}
.ml-4 {
  margin-left: map-get($spacers, 4) !important;
}
.mx-4 {
  margin-right: map-get($spacers, 4) !important;
  margin-left: map-get($spacers, 4) !important;
}
.my-4 {
  margin-top: map-get($spacers, 4) !important;
  margin-bottom: map-get($spacers, 4) !important;
}

.mt-6 {
  margin-top: map-get($spacers, 6) !important;
}
.mr-6 {
  margin-right: map-get($spacers, 6) !important;
}
.mb-6 {
  margin-bottom: map-get($spacers, 6) !important;
}
.ml-6 {
  margin-left: map-get($spacers, 6) !important;
}
.mx-6 {
  margin-right: map-get($spacers, 6) !important;
  margin-left: map-get($spacers, 6) !important;
}
.my-6 {
  margin-top: map-get($spacers, 6) !important;
  margin-bottom: map-get($spacers, 6) !important;
}

.mt-8 {
  margin-top: map-get($spacers, 8) !important;
}
.mr-8 {
  margin-right: map-get($spacers, 8) !important;
}
.mb-8 {
  margin-bottom: map-get($spacers, 8) !important;
}
.ml-8 {
  margin-left: map-get($spacers, 8) !important;
}
.mx-8 {
  margin-right: map-get($spacers, 8) !important;
  margin-left: map-get($spacers, 8) !important;
}
.my-8 {
  margin-top: map-get($spacers, 8) !important;
  margin-bottom: map-get($spacers, 8) !important;
}

.mt-10 {
  margin-top: map-get($spacers, 10) !important;
}
.mr-10 {
  margin-right: map-get($spacers, 10) !important;
}
.mb-10 {
  margin-bottom: map-get($spacers, 10) !important;
}
.ml-10 {
  margin-left: map-get($spacers, 10) !important;
}
.mx-10 {
  margin-right: map-get($spacers, 10) !important;
  margin-left: map-get($spacers, 10) !important;
}
.my-10 {
  margin-top: map-get($spacers, 10) !important;
  margin-bottom: map-get($spacers, 10) !important;
}

.mt-12 {
  margin-top: map-get($spacers, 12) !important;
}
.mr-12 {
  margin-right: map-get($spacers, 12) !important;
}
.mb-12 {
  margin-bottom: map-get($spacers, 12) !important;
}
.ml-12 {
  margin-left: map-get($spacers, 12) !important;
}
.mx-12 {
  margin-right: map-get($spacers, 12) !important;
  margin-left: map-get($spacers, 12) !important;
}
.my-12 {
  margin-top: map-get($spacers, 12) !important;
  margin-bottom: map-get($spacers, 12) !important;
}

.mt-14 {
  margin-top: map-get($spacers, 14) !important;
}
.mr-14 {
  margin-right: map-get($spacers, 14) !important;
}
.mb-14 {
  margin-bottom: map-get($spacers, 14) !important;
}
.ml-14 {
  margin-left: map-get($spacers, 14) !important;
}
.mx-14 {
  margin-right: map-get($spacers, 14) !important;
  margin-left: map-get($spacers, 14) !important;
}
.my-14 {
  margin-top: map-get($spacers, 14) !important;
  margin-bottom: map-get($spacers, 14) !important;
}

.mt-16 {
  margin-top: map-get($spacers, 16) !important;
}
.mr-16 {
  margin-right: map-get($spacers, 16) !important;
}
.mb-16 {
  margin-bottom: map-get($spacers, 16) !important;
}
.ml-16 {
  margin-left: map-get($spacers, 16) !important;
}
.mx-16 {
  margin-right: map-get($spacers, 16) !important;
  margin-left: map-get($spacers, 16) !important;
}
.my-16 {
  margin-top: map-get($spacers, 16) !important;
  margin-bottom: map-get($spacers, 16) !important;
}

.mt-18 {
  margin-top: map-get($spacers, 18) !important;
}
.mr-18 {
  margin-right: map-get($spacers, 18) !important;
}
.mb-18 {
  margin-bottom: map-get($spacers, 18) !important;
}
.ml-18 {
  margin-left: map-get($spacers, 18) !important;
}
.mx-18 {
  margin-right: map-get($spacers, 18) !important;
  margin-left: map-get($spacers, 18) !important;
}
.my-18 {
  margin-top: map-get($spacers, 18) !important;
  margin-bottom: map-get($spacers, 18) !important;
}

.mt-20 {
  margin-top: map-get($spacers, 20) !important;
}
.mr-20 {
  margin-right: map-get($spacers, 20) !important;
}
.mb-20 {
  margin-bottom: map-get($spacers, 20) !important;
}
.ml-20 {
  margin-left: map-get($spacers, 20) !important;
}
.mx-20 {
  margin-right: map-get($spacers, 20) !important;
  margin-left: map-get($spacers, 20) !important;
}
.my-20 {
  margin-top: map-get($spacers, 20) !important;
  margin-bottom: map-get($spacers, 20) !important;
}

.mt-24 {
  margin-top: map-get($spacers, 24) !important;
}
.mr-24 {
  margin-right: map-get($spacers, 24) !important;
}
.mb-24 {
  margin-bottom: map-get($spacers, 24) !important;
}
.ml-24 {
  margin-left: map-get($spacers, 24) !important;
}
.mx-24 {
  margin-right: map-get($spacers, 24) !important;
  margin-left: map-get($spacers, 24) !important;
}
.my-24 {
  margin-top: map-get($spacers, 24) !important;
  margin-bottom: map-get($spacers, 24) !important;
}

.mt-30 {
  margin-top: map-get($spacers, 30) !important;
}
.mr-30 {
  margin-right: map-get($spacers, 30) !important;
}
.mb-30 {
  margin-bottom: map-get($spacers, 30) !important;
}
.ml-30 {
  margin-left: map-get($spacers, 30) !important;
}
.mx-30 {
  margin-right: map-get($spacers, 30) !important;
  margin-left: map-get($spacers, 30) !important;
}
.my-30 {
  margin-top: map-get($spacers, 30) !important;
  margin-bottom: map-get($spacers, 30) !important;
}

.mt-32 {
  margin-top: map-get($spacers, 32) !important;
}
.mr-32 {
  margin-right: map-get($spacers, 32) !important;
}
.mb-32 {
  margin-bottom: map-get($spacers, 32) !important;
}
.ml-32 {
  margin-left: map-get($spacers, 32) !important;
}
.mx-32 {
  margin-right: map-get($spacers, 32) !important;
  margin-left: map-get($spacers, 32) !important;
}
.my-32 {
  margin-top: map-get($spacers, 32) !important;
  margin-bottom: map-get($spacers, 32) !important;
}

.mt-34 {
  margin-top: map-get($spacers, 34) !important;
}
.mr-34 {
  margin-right: map-get($spacers, 34) !important;
}
.mb-34 {
  margin-bottom: map-get($spacers, 34) !important;
}
.ml-34 {
  margin-left: map-get($spacers, 34) !important;
}
.mx-34 {
  margin-right: map-get($spacers, 34) !important;
  margin-left: map-get($spacers, 34) !important;
}
.my-34 {
  margin-top: map-get($spacers, 34) !important;
  margin-bottom: map-get($spacers, 34) !important;
}

.mt-40 {
  margin-top: map-get($spacers, 40) !important;
}
.mr-40 {
  margin-right: map-get($spacers, 40) !important;
}
.mb-40 {
  margin-bottom: map-get($spacers, 40) !important;
}
.ml-40 {
  margin-left: map-get($spacers, 40) !important;
}
.mx-40 {
  margin-right: map-get($spacers, 40) !important;
  margin-left: map-get($spacers, 40) !important;
}
.my-40 {
  margin-top: map-get($spacers, 40) !important;
  margin-bottom: map-get($spacers, 40) !important;
}

.mt-45 {
  margin-top: map-get($spacers, 45) !important;
}
.mr-45 {
  margin-right: map-get($spacers, 45) !important;
}
.mb-45 {
  margin-bottom: map-get($spacers, 45) !important;
}
.ml-45 {
  margin-left: map-get($spacers, 45) !important;
}
.mx-45 {
  margin-right: map-get($spacers, 45) !important;
  margin-left: map-get($spacers, 45) !important;
}
.my-45 {
  margin-top: map-get($spacers, 45) !important;
  margin-bottom: map-get($spacers, 45) !important;
}

.mt-48 {
  margin-top: map-get($spacers, 48) !important;
}
.mr-48 {
  margin-right: map-get($spacers, 48) !important;
}
.mb-48 {
  margin-bottom: map-get($spacers, 48) !important;
}
.ml-48 {
  margin-left: map-get($spacers, 48) !important;
}
.mx-48 {
  margin-right: map-get($spacers, 48) !important;
  margin-left: map-get($spacers, 48) !important;
}
.my-48 {
  margin-top: map-get($spacers, 48) !important;
  margin-bottom: map-get($spacers, 48) !important;
}

.mt-56 {
  margin-top: map-get($spacers, 56) !important;
}
.mr-56 {
  margin-right: map-get($spacers, 56) !important;
}
.mb-56 {
  margin-bottom: map-get($spacers, 56) !important;
}
.ml-56 {
  margin-left: map-get($spacers, 56) !important;
}
.mx-56 {
  margin-right: map-get($spacers, 56) !important;
  margin-left: map-get($spacers, 56) !important;
}
.my-56 {
  margin-top: map-get($spacers, 56) !important;
  margin-bottom: map-get($spacers, 56) !important;
}

.mt-60 {
  margin-top: map-get($spacers, 60) !important;
}
.mr-60 {
  margin-right: map-get($spacers, 60) !important;
}
.mb-60 {
  margin-bottom: map-get($spacers, 60) !important;
}
.ml-60 {
  margin-left: map-get($spacers, 60) !important;
}
.mx-60 {
  margin-right: map-get($spacers, 60) !important;
  margin-left: map-get($spacers, 60) !important;
}
.my-60 {
  margin-top: map-get($spacers, 60) !important;
  margin-bottom: map-get($spacers, 60) !important;
}

.mt-64 {
  margin-top: map-get($spacers, 64) !important;
}
.mr-64 {
  margin-right: map-get($spacers, 64) !important;
}
.mb-64 {
  margin-bottom: map-get($spacers, 64) !important;
}
.ml-64 {
  margin-left: map-get($spacers, 64) !important;
}
.mx-64 {
  margin-right: map-get($spacers, 64) !important;
  margin-left: map-get($spacers, 64) !important;
}
.my-64 {
  margin-top: map-get($spacers, 64) !important;
  margin-bottom: map-get($spacers, 64) !important;
}

.mt-70 {
  margin-top: map-get($spacers, 70) !important;
}
.mr-70 {
  margin-right: map-get($spacers, 70) !important;
}
.mb-70 {
  margin-bottom: map-get($spacers, 70) !important;
}
.ml-70 {
  margin-left: map-get($spacers, 70) !important;
}
.mx-70 {
  margin-right: map-get($spacers, 70) !important;
  margin-left: map-get($spacers, 70) !important;
}
.my-70 {
  margin-top: map-get($spacers, 70) !important;
  margin-bottom: map-get($spacers, 70) !important;
}

.mt-100 {
  margin-top: map-get($spacers, 100) !important;
}
.mr-100 {
  margin-right: map-get($spacers, 100) !important;
}
.mb-100 {
  margin-bottom: map-get($spacers, 100) !important;
}
.ml-100 {
  margin-left: map-get($spacers, 100) !important;
}
.mx-100 {
  margin-right: map-get($spacers, 100) !important;
  margin-left: map-get($spacers, 100) !important;
}
.my-100 {
  margin-top: map-get($spacers, 100) !important;
  margin-bottom: map-get($spacers, 100) !important;
}

.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.mt--50 {
  margin-top: -50px !important;
}
.ml-270 {
  margin-left: 270px !important;
}

// -------------------------- PADDING --------------------------

.p-0 {
  padding: 0 !important;
}
.p-2 {
  padding: map-get($spacers, 2) !important;
}
.p-4 {
  padding: map-get($spacers, 4) !important;
}
.p-6 {
  padding: map-get($spacers, 6) !important;
}
.p-8 {
  padding: map-get($spacers, 8) !important;
}
.p-10 {
  padding: map-get($spacers, 10) !important;
}
.p-12 {
  padding: map-get($spacers, 12) !important;
}
.p-14 {
  padding: map-get($spacers, 14) !important;
}
.p-16 {
  padding: map-get($spacers, 16) !important;
}
.p-18 {
  padding: map-get($spacers, 18) !important;
}
.p-20 {
  padding: map-get($spacers, 20) !important;
}
.p-24 {
  padding: map-get($spacers, 24) !important;
}
.p-30 {
  padding: map-get($spacers, 30) !important;
}
.p-32 {
  padding: map-get($spacers, 32) !important;
}
.p-34 {
  padding: map-get($spacers, 34) !important;
}
.p-40 {
  padding: map-get($spacers, 40) !important;
}
.p-45 {
  padding: map-get($spacers, 45) !important;
}
.p-48 {
  padding: map-get($spacers, 48) !important;
}
.p-56 {
  padding: map-get($spacers, 56) !important;
}
.p-64 {
  padding: map-get($spacers, 64) !important;
}
.p-70 {
  padding: map-get($spacers, 70) !important;
}
.p-100 {
  padding: map-get($spacers, 100) !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.-pl-0 {
  --padding-start: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-2 {
  padding-top: map-get($spacers, 2) !important;
}
.pr-2 {
  padding-right: map-get($spacers, 2) !important;
}
.pb-2 {
  padding-bottom: map-get($spacers, 2) !important;
}
.pl-2 {
  padding-left: map-get($spacers, 2) !important;
}
.px-2 {
  padding-right: map-get($spacers, 2) !important;
  padding-left: map-get($spacers, 2) !important;
}
.py-2 {
  padding-top: map-get($spacers, 2) !important;
  padding-bottom: map-get($spacers, 2) !important;
}

.pt-4 {
  padding-top: map-get($spacers, 4) !important;
}
.pr-4 {
  padding-right: map-get($spacers, 4) !important;
}
.pb-4 {
  padding-bottom: map-get($spacers, 4) !important;
}
.pl-4 {
  padding-left: map-get($spacers, 4) !important;
}
.px-4 {
  padding-right: map-get($spacers, 4) !important;
  padding-left: map-get($spacers, 4) !important;
}
.py-4 {
  padding-top: map-get($spacers, 4) !important;
  padding-bottom: map-get($spacers, 4) !important;
}

.pt-6 {
  padding-top: map-get($spacers, 6) !important;
}
.pr-6 {
  padding-right: map-get($spacers, 6) !important;
}
.pb-6 {
  padding-bottom: map-get($spacers, 6) !important;
}
.pl-6 {
  padding-left: map-get($spacers, 6) !important;
}
.px-6 {
  padding-right: map-get($spacers, 6) !important;
  padding-left: map-get($spacers, 6) !important;
}
.py-6 {
  padding-top: map-get($spacers, 6) !important;
  padding-bottom: map-get($spacers, 6) !important;
}

.pt-8 {
  padding-top: map-get($spacers, 8) !important;
}
.pr-8 {
  padding-right: map-get($spacers, 8) !important;
}
.pb-8 {
  padding-bottom: map-get($spacers, 8) !important;
}
.pl-8 {
  padding-left: map-get($spacers, 8) !important;
}
.px-8 {
  padding-right: map-get($spacers, 8) !important;
  padding-left: map-get($spacers, 8) !important;
}
.py-8 {
  padding-top: map-get($spacers, 8) !important;
  padding-bottom: map-get($spacers, 8) !important;
}

.pt-10 {
  padding-top: map-get($spacers, 10) !important;
}
.pr-10 {
  padding-right: map-get($spacers, 10) !important;
}
.pb-10 {
  padding-bottom: map-get($spacers, 10) !important;
}
.pl-10 {
  padding-left: map-get($spacers, 10) !important;
}
.px-10 {
  padding-right: map-get($spacers, 10) !important;
  padding-left: map-get($spacers, 10) !important;
}
.py-10 {
  padding-top: map-get($spacers, 10) !important;
  padding-bottom: map-get($spacers, 10) !important;
}

.pt-12 {
  padding-top: map-get($spacers, 12) !important;
}
.pr-12 {
  padding-right: map-get($spacers, 12) !important;
}
.pb-12 {
  padding-bottom: map-get($spacers, 12) !important;
}
.pl-12 {
  padding-left: map-get($spacers, 12) !important;
}
.px-12 {
  padding-right: map-get($spacers, 12) !important;
  padding-left: map-get($spacers, 12) !important;
}
.py-12 {
  padding-top: map-get($spacers, 12) !important;
  padding-bottom: map-get($spacers, 12) !important;
}

.pt-14 {
  padding-top: map-get($spacers, 14) !important;
}
.pr-14 {
  padding-right: map-get($spacers, 14) !important;
}
.pb-14 {
  padding-bottom: map-get($spacers, 14) !important;
}
.pl-14 {
  padding-left: map-get($spacers, 14) !important;
}
.px-14 {
  padding-right: map-get($spacers, 14) !important;
  padding-left: map-get($spacers, 14) !important;
}
.py-14 {
  padding-top: map-get($spacers, 14) !important;
  padding-bottom: map-get($spacers, 14) !important;
}

.pt-16 {
  padding-top: map-get($spacers, 16) !important;
}
.pr-16 {
  padding-right: map-get($spacers, 16) !important;
}
.pb-16 {
  padding-bottom: map-get($spacers, 16) !important;
}
.pl-16 {
  padding-left: map-get($spacers, 16) !important;
}
.px-16 {
  padding-right: map-get($spacers, 16) !important;
  padding-left: map-get($spacers, 16) !important;
}
.py-16 {
  padding-top: map-get($spacers, 16) !important;
  padding-bottom: map-get($spacers, 16) !important;
}

.pt-18 {
  padding-top: map-get($spacers, 18) !important;
}
.pr-18 {
  padding-right: map-get($spacers, 18) !important;
}
.pb-18 {
  padding-bottom: map-get($spacers, 18) !important;
}
.pl-18 {
  padding-left: map-get($spacers, 18) !important;
}
.px-18 {
  padding-right: map-get($spacers, 18) !important;
  padding-left: map-get($spacers, 18) !important;
}
.py-18 {
  padding-top: map-get($spacers, 18) !important;
  padding-bottom: map-get($spacers, 18) !important;
}

.pt-20 {
  padding-top: map-get($spacers, 20) !important;
}
.pr-20 {
  padding-right: map-get($spacers, 20) !important;
}
.pb-20 {
  padding-bottom: map-get($spacers, 20) !important;
}
.pl-20 {
  padding-left: map-get($spacers, 20) !important;
}
.px-20 {
  padding-right: map-get($spacers, 20) !important;
  padding-left: map-get($spacers, 20) !important;
}
.py-20 {
  padding-top: map-get($spacers, 20) !important;
  padding-bottom: map-get($spacers, 20) !important;
}

.pt-24 {
  padding-top: map-get($spacers, 24) !important;
}
.pr-24 {
  padding-right: map-get($spacers, 24) !important;
}
.pb-24 {
  padding-bottom: map-get($spacers, 24) !important;
}
.pl-24 {
  padding-left: map-get($spacers, 24) !important;
}
.px-24 {
  padding-right: map-get($spacers, 24) !important;
  padding-left: map-get($spacers, 24) !important;
}
.py-24 {
  padding-top: map-get($spacers, 24) !important;
  padding-bottom: map-get($spacers, 24) !important;
}

.pt-30 {
  padding-top: map-get($spacers, 30) !important;
}
.pr-30 {
  padding-right: map-get($spacers, 30) !important;
}
.pb-30 {
  padding-bottom: map-get($spacers, 30) !important;
}
.pl-30 {
  padding-left: map-get($spacers, 30) !important;
}
.px-30 {
  padding-right: map-get($spacers, 30) !important;
  padding-left: map-get($spacers, 30) !important;
}
.py-30 {
  padding-top: map-get($spacers, 30) !important;
  padding-bottom: map-get($spacers, 30) !important;
}

.pt-32 {
  padding-top: map-get($spacers, 32) !important;
}
.pr-32 {
  padding-right: map-get($spacers, 32) !important;
}
.pb-32 {
  padding-bottom: map-get($spacers, 32) !important;
}
.pl-32 {
  padding-left: map-get($spacers, 32) !important;
}
.px-32 {
  padding-right: map-get($spacers, 32) !important;
  padding-left: map-get($spacers, 32) !important;
}
.py-32 {
  padding-top: map-get($spacers, 32) !important;
  padding-bottom: map-get($spacers, 32) !important;
}

.pt-34 {
  padding-top: map-get($spacers, 34) !important;
}
.pr-34 {
  padding-right: map-get($spacers, 34) !important;
}
.pb-34 {
  padding-bottom: map-get($spacers, 34) !important;
}
.pl-34 {
  padding-left: map-get($spacers, 34) !important;
}
.px-34 {
  padding-right: map-get($spacers, 34) !important;
  padding-left: map-get($spacers, 34) !important;
}
.py-34 {
  padding-top: map-get($spacers, 34) !important;
  padding-bottom: map-get($spacers, 34) !important;
}

.pt-40 {
  padding-top: map-get($spacers, 40) !important;
}
.pr-40 {
  padding-right: map-get($spacers, 40) !important;
}
.pb-40 {
  padding-bottom: map-get($spacers, 40) !important;
}
.pl-40 {
  padding-left: map-get($spacers, 40) !important;
}
.px-40 {
  padding-right: map-get($spacers, 40) !important;
  padding-left: map-get($spacers, 40) !important;
}
.py-40 {
  padding-top: map-get($spacers, 40) !important;
  padding-bottom: map-get($spacers, 40) !important;
}

.pt-45 {
  padding-top: map-get($spacers, 45) !important;
}
.pr-45 {
  padding-right: map-get($spacers, 45) !important;
}
.pb-45 {
  padding-bottom: map-get($spacers, 45) !important;
}
.pl-45 {
  padding-left: map-get($spacers, 45) !important;
}
.px-45 {
  padding-right: map-get($spacers, 45) !important;
  padding-left: map-get($spacers, 45) !important;
}
.py-45 {
  padding-top: map-get($spacers, 45) !important;
  padding-bottom: map-get($spacers, 45) !important;
}

.pt-48 {
  padding-top: map-get($spacers, 48) !important;
}
.pr-48 {
  padding-right: map-get($spacers, 48) !important;
}
.pb-48 {
  padding-bottom: map-get($spacers, 48) !important;
}
.pl-48 {
  padding-left: map-get($spacers, 48) !important;
}
.px-48 {
  padding-right: map-get($spacers, 48) !important;
  padding-left: map-get($spacers, 48) !important;
}
.py-48 {
  padding-top: map-get($spacers, 48) !important;
  padding-bottom: map-get($spacers, 48) !important;
}

.pt-56 {
  padding-top: map-get($spacers, 56) !important;
}
.pr-56 {
  padding-right: map-get($spacers, 56) !important;
}
.pb-56 {
  padding-bottom: map-get($spacers, 56) !important;
}
.pl-56 {
  padding-left: map-get($spacers, 56) !important;
}
.px-56 {
  padding-right: map-get($spacers, 56) !important;
  padding-left: map-get($spacers, 56) !important;
}
.py-56 {
  padding-top: map-get($spacers, 56) !important;
  padding-bottom: map-get($spacers, 56) !important;
}

.pt-60 {
  padding-top: map-get($spacers, 60) !important;
}
.pr-60 {
  padding-right: map-get($spacers, 60) !important;
}
.pb-60 {
  padding-bottom: map-get($spacers, 60) !important;
}
.pl-60 {
  padding-left: map-get($spacers, 60) !important;
}
.px-60 {
  padding-right: map-get($spacers, 60) !important;
  padding-left: map-get($spacers, 60) !important;
}
.py-60 {
  padding-top: map-get($spacers, 60) !important;
  padding-bottom: map-get($spacers, 60) !important;
}

.pt-64 {
  padding-top: map-get($spacers, 64) !important;
}
.pr-64 {
  padding-right: map-get($spacers, 64) !important;
}
.pb-64 {
  padding-bottom: map-get($spacers, 64) !important;
}
.pl-64 {
  padding-left: map-get($spacers, 64) !important;
}
.px-64 {
  padding-right: map-get($spacers, 64) !important;
  padding-left: map-get($spacers, 64) !important;
}
.py-64 {
  padding-top: map-get($spacers, 64) !important;
  padding-bottom: map-get($spacers, 64) !important;
}

.pt-70 {
  padding-top: map-get($spacers, 70) !important;
}
.pr-70 {
  padding-right: map-get($spacers, 70) !important;
}
.pb-70 {
  padding-bottom: map-get($spacers, 70) !important;
}
.pl-70 {
  padding-left: map-get($spacers, 70) !important;
}
.px-70 {
  padding-right: map-get($spacers, 70) !important;
  padding-left: map-get($spacers, 70) !important;
}
.py-70 {
  padding-top: map-get($spacers, 70) !important;
  padding-bottom: map-get($spacers, 70) !important;
}

.pt-100 {
  padding-top: map-get($spacers, 100) !important;
}
.pr-100 {
  padding-right: map-get($spacers, 100) !important;
}
.pb-100 {
  padding-bottom: map-get($spacers, 100) !important;
}
.pl-100 {
  padding-left: map-get($spacers, 100) !important;
}
.px-100 {
  padding-right: map-get($spacers, 100) !important;
  padding-left: map-get($spacers, 100) !important;
}
.py-100 {
  padding-top: map-get($spacers, 100) !important;
  padding-bottom: map-get($spacers, 100) !important;
}

// ----------------------- WIDTH -----------------------------

.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-33 {
  width: 33% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}
.ww-100 {
  width: 100vw !important;
  --width: 100vw !important;
}
.w-0px {
  width: 0px !important;
}
.w-1px {
  width: 1px !important;
}
.w-10px {
  width: 10px !important;
}
.w-20px {
  width: 20px !important;
}
.w-24px {
  width: 24px !important;
}
.w-26px {
  width: 26px !important;
}
.w-30px {
  width: 30px !important;
}
.w-32px {
  width: 32px !important;
}
.w-34px {
  width: 34px !important;
}
.w-36px {
  width: 36px !important;
}
.w-40px {
  width: 40px !important;
}
.w-42px {
  width: 42px !important;
}
.w-45px {
  width: 45px !important;
}
.w-50px {
  width: 50px !important;
}
.w-55px {
  width: 55px !important;
}
.w-60px {
  width: 60px !important;
}
.w-62px {
  width: 62px !important;
}
.w-70px {
  width: 70px !important;
}
.w-75px {
  width: 75px !important;
}
.w-80px {
  width: 80px !important;
}
.w-90px {
  width: 90px !important;
}
.w-100px {
  width: 100px !important;
}
.w-110px {
  width: 110px !important;
}
.w-120px {
  width: 120px !important;
}
.w-130px {
  width: 130px !important;
}
.w-135px {
  width: 135px !important;
}
.w-140px {
  width: 140px !important;
}
.w-150px {
  width: 150px !important;
}
.w-160px {
  width: 160px !important;
}
.w-180px {
  width: 180px !important;
}
.w-200px {
  width: 200px !important;
}
.w-220px {
  width: 220px !important;
}
.w-300px {
  width: 300px !important;
}
.w-400px {
  width: 400px !important;
}

.max-w-auto {
  max-width: auto !important;
}
.min-w-auto {
  min-width: auto !important;
}

.max-w-100 {
  max-width: 100% !important;
}
.max-w-40px {
  max-width: 40px !important;
}
.max-w-62px {
  max-width: 62px !important;
}
.max-w-150px {
  max-width: 150px !important;
}
.max-w-180px {
  max-width: 180px !important;
}
.max-w-220px {
  max-width: 220px !important;
}
.max-w-230px {
  max-width: 230px !important;
}
.max-w-300px {
  max-width: 300px !important;
}
.max-w-320 {
  max-width: 320px !important;
}
.max-w-360px {
  max-width: 360px !important;
}

.-max-w-520px {
  --max-width: 520px !important;
}
.-max-w-700px {
  --max-width: 700px !important;
}
.-max-w-800px {
  --max-width: 800px !important;
}
.-max-w-1060px {
  --max-width: 1060px !important;
}

.min-w-100 {
  min-width: 100% !important;
}
.min-w-130 {
  min-width: 130px !important;
}
.min-w-200px {
  min-width: 200px !important;
}
.min-w-220px {
  min-width: 220px !important;
}
.min-w-250px {
  min-width: 250px !important;
}
.min-w-300px {
  min-width: 300px !important;
}
.min-w-320px {
  min-width: 320px !important;
}
.min-w-360px {
  min-width: 360px !important;
}
.min-w-700px {
  min-width: 700px !important;
}

// ----------------------- HEIGHT -----------------------------

.h-10 {
  height: 10% !important;
}
.h-15 {
  height: 15% !important;
}
.h-20 {
  height: 20% !important;
}
.h-25 {
  height: 25% !important;
}
.h-30 {
  height: 30% !important;
}
.h-35 {
  height: 35% !important;
}
.h-40 {
  height: 40% !important;
}
.h-45 {
  height: 45% !important;
}
.h-50 {
  height: 50% !important;
}
.h-55 {
  height: 55% !important;
}
.h-60 {
  height: 60% !important;
}
.h-65 {
  height: 65% !important;
}
.h-70 {
  height: 70% !important;
}
.h-75 {
  height: 75% !important;
}
.h-80 {
  height: 80% !important;
}
.h-85 {
  height: 85% !important;
}
.h-90 {
  height: 90% !important;
}
.h-95 {
  height: 95% !important;
}
.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}
.hh-100 {
  height: 100vh !important;
}
.h-0px {
  height: 0px !important;
}
.h-10px {
  height: 10px !important;
}
.h-20px {
  height: 20px !important;
}
.h-24px {
  height: 24px !important;
}
.h-26px {
  height: 26px !important;
}
.h-30px {
  height: 30px !important;
}
.h-32px {
  height: 32px !important;
}
.h-34px {
  height: 34px !important;
}
.h-36px {
  height: 36px !important;
}
.h-40px {
  height: 40px !important;
}
.h-42px {
  height: 42px !important;
}
.h-45px {
  height: 45px !important;
}
.h-50px {
  height: 50px !important;
}
.h-55px {
  height: 55px !important;
}
.h-60px {
  height: 60px !important;
}
.h-64px {
  height: 64px !important;
}
.h-70px {
  height: 70px !important;
}
.h-75px {
  height: 75px !important;
}
.h-80px {
  height: 80px !important;
}
.h-90px {
  height: 90px !important;
}
.h-100px {
  height: 100px !important;
}
.h-110px {
  height: 110px !important;
}
.h-120px {
  height: 120px !important;
}
.h-130px {
  height: 130px !important;
}
.h-140px {
  height: 140px !important;
}
.h-150px {
  height: 150px !important;
}
.h-160px {
  height: 160px !important;
}
.h-180px {
  height: 180px !important;
}
.h-200px {
  height: 200px !important;
}
.h-220px {
  height: 220px !important;
}
.h-300px {
  height: 300px !important;
}

.max-h-auto {
  max-height: auto !important;
}
.min-h-auto {
  min-height: auto !important;
}

.min-h-150px {
  min-height: 150px !important;
}
.min-h-300px {
  min-height: 300px !important;
}
.min-h-320px {
  min-height: 320px !important;
}

.min-h-500 {
  min-height: 500px !important;
}

.max-h-40px {
  max-height: 40px !important;
}
.max-h-100px {
  max-height: 100px !important;
}
.max-h-110px {
  max-height: 110px !important;
}
.max-h-150px {
  max-height: 150px !important;
}
.max-h-200px {
  max-height: 200px !important;
}
.max-h-220px {
  max-height: 220px !important;
}
.max-h-250px {
  max-height: 250px !important;
}
.max-h-300px {
  max-height: 300px !important;
}

.min-max-content {
  min-width: max-content !important;
}

.l-height-normal {
  line-height: normal !important;
}

.btn {
  font-weight: 500;
  font-size: 0.8125em;
  border-radius: 4px;
  outline: 0 !important;
  border: none;
  padding: 14px 10px;
}

.btn-return,
.btn-tab {
  text-transform: uppercase;
  height: 35px;
}

.btn-return {
  background-color: var(--psr-btn-return);
  color: var(--psr-text-color);
}

.close-btn {
  background-color: transparent;
  border: 0px;
  color: var(--psr-equis);
  font-size: 1.5em;
  padding: 10px;
}

.btn-tab {
  background-color: var(--psr-btn-tab);
  color: var(--psr-radio-button-bg);
}

.btn-save,
.btn-cancel,
.btn-blue {
  text-transform: uppercase;
  height: 45px;
  width: 150px;
}

.btn-save {
  background-color: var(--psr-radio-button-bg);
  color: var(--psr-text-light);
  .focus,
  &:focus,
  &:hover {
    color: var(--psr-text-light);
  }
  &:disabled {
    background: var(--psr-light);
    color: var(--psr-disabled-color);
  }
}

.btn-cancel {
  background-color: var(--psr-light) !important;
  color: var(--psr-text-color);
  .focus,
  &:focus,
  &:hover {
    color: var(--psr-text-color);
  }
  &:disabled {
    color: var(--psr-disabled-color);
  }
}

.btn-square-40 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--psr-font-color-primary);
  border: 1px solid var(--psr-input-border);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  color: var(--psr-text-color);
}

.btn-cancel-gray {
  background-color: var(--psr-input-border);
  border: 1px solid var(--psr-input-border);
  color: var(--psr-text-color);
}

.btn-medium {
  width: 100%;
  @media screen and (min-width: 480px) {
    width: 50%;
  }
}

.btn-blue {
  background-color: var(--psr-color-checks);
  color: var(--psr-font-color-primary);
}

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  color-scheme: light;

  // TODO - REVIEW DARK MODE VARIABLES
  --passer-color-primary: #604897;
  --passer-error: #EB6383;
  --passer-succes: #46B8A9;
  --passer-warning: #FFD385;

  --bg-primary: #FCFCFF;
  --bg-secondary:#7F6CAB;
  --bg-ternary: #455A64;
  --bg-gray: #ECECFB;
  --bg-gray-content: #FBFBFE;
  --bg-light: #FFFFFF;
  --bg-dark:#1A194A;
  --bg-purple:#604897;
  --bg-light-purple: #BB8BFE;

  --bg-primary-background: #FAFAFA;
  --bg-disabled: #B9B9C8;
  --bg-next: #43326a;
  --bg-dark-purple: #705AA1;
  --bg-honey-flower: #6772E5;
  --bg-consulting: #EFF1F7;
  --bg-consulting-50: #F8FAFC;
  --bg-ccc: #CCC;
  --bg-material: whitesmoke;// rgba(0,0,0,.04);
  --bg-material-toggle: #00000061;
  --bg-warning: #FFF0D6;
  --bg-table: #E9F2F8;
  --bg-table-hover: #DCEEFC;
  --bg-empty: #EAEAF0;
  --bg-blue:#0C0B31;
  --bg-success: #F7FFF7;
  --bg-error: #FF3F45;
  --bg-red-alert-25: #ff3f4540;
  --bg-shadow-opacity: #dddddd2b;
  --bg-warning-box: #F9F5C5;
  --bg-purple-box: #E2DCF1;
  --bg-blue-box: #CEE9FA;
  
  --button-primary: #604897;
  --button-secondary: #FFFFFF;
  --button-ternary: #ECECFB;
  --button-purple: #9078C7;
  --button-light-purple: #BB8BFE;
  --button-gray: #ECECFB;
  --button-back: #6200EE0A;
  --button-cancel: #DBDBEA;

  --psr-shadow: #CACAD9;
  --psr-btn-return: #0000000A;
  --psr-color-checks: #007FFF;
  --psr-text-color: #ffffff;
  --psr-bbb: #bbb;
  --psr-text-subtitle: #9326FF;
  --psr-star:#E0E03C;
  --psr-whatsapp-green: #44BE53;
  
  --text-primary:#0C0B31;
  --text-secondary: #404051;
  --text-gray:#B9B9C8;
  --text-light:#f4f4f4;
  --text-dark:#000000;
  --text-white:#FFFFFF;
  
  --text-focus: #604897;
  --text-link: #007FFF;
  --text-placerholder: #797985;
  --text-purple: #604897;
  --text-purple-light: #a75af9;
  --text-purple-87: #604897DE;
  --text-title-modal:#217ab7;
  --text-focus-purple:#E0C8FF;
  --text-empty: #9595a2;
  --text-alert-warning: #8a6d3b;
  
  --input-bg-primary:#ECECFB;
  --input-bg-secondary:#FFFFFF;
  --input-border: #4d4d4d;
  --input-border-62: #DBDBEA9E;
  --input-border-29: #DBDBEA29;
  --border-primary: #CACAD9;
  --border-secondary: #DBDBEA;
  --border-purple: #604897;
  --border-warning: #FFB636;
  --border-success: #74E4A2;
  --border-error: #FF3F45;

  --shadow-box-primary: #ECECFB;
  --shadow-light: #DDDDDD;
  --shadow-box-header: #DBDBEA70;

  --icon-red: #ff3f45;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  // TODO - REVIEW THEN  AND DELETE/CHANGE BY VARIABLES ABOVE
  --psr-link: #35ace6;
  --psr-link-17: #35ace62b;
  --psr-font-success: #f7fff7;
  --psr-light-green: #85f37d;
  --psr-mesage: #74e4a2;
  --psr-progress-bar-green: #03d117;
  --psr-whatsapp-green: #44be53;
  --psr-text-modal-succ: #139270;
  --psr-positiveText: #149470;
  --psr-green-text: #09b200;
  --psr-alert-warning: #8a6d3b;
  --psr-alert-info: #055160; // SE MANTIENE
  --psr-yellow: #f3ea7d;
  --psr-star-yellow: #e0e03c;
  --psr-border-warning: #faebcc;
  --psr-backgroung-warning: #fcf8e3;
  --psr-backgroung-info: #f1fcf6; // SE MANTIENE
  --psr-app-aside-nav-head-bg: #ececfb;
  --psr-background-cell: #cee9fa;
  --psr-background-empty: #eaeaf0;
  --psr-background-material: rgba(0, 0, 0, 0.04);
  --psr-bbb: #bbb;
  --psr-box-value: #e9f2f8;
  --psr-border-container: #f5f5f5;
  --psr-bg-checks: #ddf1fb;
  --psr-color-checks: #217ab7;

  --psr-cyan-variation: #35e0ff;
  --psr-cyan-dark: #14a7c2;
  --psr-check-view: #609ed6;
  --psr-pref-box: #25ade2;
  --psr-text-title-modal: #217ab7;
  --psr-secondary: #007fff;
  --psr-selected-seat: #8c95ef;
  --psr-btn: #6772e5;
  --psr-text-link-pur: #5d3f6c;
  --psr-radio-button-bg: #604897;
  --psr-radio-button-bg-87: #604897de;
  --psr-text-subtitle: #9326ff;
  --psr-primary: #9c64c0;
  --psr-purple-tabs: #a75af9;
  --psr-light-purple: #bb8bfe;
  --psr-lighter-purple: #e6d5ff;

  --psr-btn-return: #0000000a;
  --psr-btn-tab: #6200ee0a;
  --psr-font-color-primary: #fff;
  --psr-input-border-29: #dbdbea29;
  --psr-input-border-62: #dbdbea9e;
  --psr-input-border-70: #dbdbea70;
  --psr-dark: #333333;
  --psr-disabled-color: #b9b9c8;
  --psr-empty: #9595a2;
  --psr-shadow-box: #dbdbea70;
  --psr-text-color: #404051;
  --psr-text-color-55: #555;
  --psr-sombra: #cacad9;
  --psr-rating: #a9a9a9;
  --psr-camera-opac: #b8b8c7;
  --psr-camera-opac-37: rgba(184, 184, 199, 0.37);
  --psr-ccc: #ccc;
  --psr-toggle-bg: #f0f0f0;
  --psr-checkbox-border: #d8d8d8;
  --psr-sec-back-dark: #f1f5f9;
  --psr-secondary-background: #f8fafc;
  --psr-input-border: #dbdbea;
  --psr-input-group-hover: #f9f8f8;
  --psr-light: #dddddd;
  --psr-light-gray: #f3f2f3;
  --psr-radio-button-border: #dedede;
  --psr-primary-background: #fafafa;
  --psr-text-muted: #efefef;

  --psr-danger: #f05050;
  --psr-danger-variation: #bd3232;
  --psr-equis: #ff3f45;
  --psr-naranja: #ff3f00;
  --psr-suspended: #f38d7d;
  --psr-suspended-box: #f3c6b7;

  --psr-color-light-style: #dce8ef;
  --psr-color-light-style-text: #604897;

  --psr-color-dark-style: #7e7689;
  --psr-color-dark-style-text: #ffffff;

  --psr-color-background-box: #ffffff;
  --psr-text-light: #ffffff;
  --psr-background-number: #f2f5fa;

  --psr-color-warning: #fcf8e3;
  --psr-border-warning: #faebcc;
}

body.dark-theme {
  color-scheme: dark;

  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --psr-link: #35ace6; // FALTA
  --psr-link-17: #35ace62b; // FALTA
  --psr-font-success: #f7fff7; // SE MANTIENE
  --psr-light-green: #19a80f;
  --psr-mesage: #b9f1d0;
  --psr-progress-bar-green: #6cfc7a;
  --psr-whatsapp-green: #44be53; // SE MANTIENE
  --psr-text-modal-succ: #66ebc7;
  --psr-positiveText: #68eac5;

  --psr-alert-warning: #8a6d3b; // SE MANTIENE
  --psr-alert-info: #055160; // SE MANTIENE
  --psr-yellow: #f3ea7d; // POSIBLE: #a89c0f
  --psr-star-yellow: #efef9d;
  --psr-border-warning: #faebcc; // SE MANTIENE
  --psr-backgroung-warning: #fcf8e3; // SE MANTIENE
  --psr-backgroung-info: #eef9ff; // SE MANTIENE

  --psr-background-cell: #1587ce;
  --psr-background-empty: #62628a;
  --psr-background-material: rgba(0, 0, 0, 0.04); // POSIBLE #00000066;
  --psr-bbb: #dddddd;

  --psr-bg-checks: #1895d3;
  --psr-color-checks: #82bfe8;
  --psr-border-container: #7a7a7a;

  --psr-box-value: #3a84b6;
  --psr-cyan-variation: #00829a;
  --psr-cyan-dark: #78def1;
  --psr-check-view: #afceea;
  --psr-pref-box: #25ade2; // POSIBLE #0f5774
  --psr-text-title-modal: #82bfe8;
  --psr-secondary: #7fbfff;

  --psr-selected-seat: #8c95ef; // POSIBLE #4957e5
  --psr-btn: #6772e5; // POSIBLE #18228d
  --psr-text-link-pur: #b193c0;
  --psr-radio-button-bg: #604897; // POSIBLE #ad9dd0
  --psr-radio-button-bg-87: #604897de; // FALTA
  --psr-text-subtitle: #c992fe;
  --psr-primary: #9c64c0; // POSIBLE DARK #4f2a67, light: #cdb1df
  --psr-purple-tabs: #a75af9; // FALTA
  --psr-light-purple: #bb8bfe; // FALTA
  --psr-lighter-purple: #e6d5ff; // FALTA
  --psr-btn-tab: #6200ee0a; // POSIBLE #6200EEcc
  --psr-btn-return: #0000000a; // REVISAR

  --psr-empty: #cacad0;
  --psr-text-color-55: #aaaaaa;
  --psr-rating: #a9a9a9; // POSIBLE DARK: #545454, LIGHT: #d3d3d3;
  --psr-camera-opac: #b8b8c7; // SE MANTIENE
  --psr-camera-opac-5: rgba(184, 184, 199, 0.5); // REVISAR
  --psr-ccc: #ccc; // SE MANTIENE // REVISAR
  --psr-toggle-bg: #f0f0f0; // SE MANTIENE // REVISAR
  --psr-checkbox-border: #6c6c6c;
  --psr-sec-back-dark: #497aab;
  --psr-input-group-hover: #857272;
  --psr-light: #dddddd; // POSIBLE DARK: #6e6e6e, LIGHT: #eeeeee
  --psr-light-gray: #7e747e;
  --psr-radio-button-border: #6f6f6f;
  --psr-primary-background: #fafafa; // POSIBLE #313131
  --psr-text-muted: #777777;

  --psr-app-aside-nav-head-bg: #595462;
  --psr-font-color-primary: #000000;
  --psr-dark: #eff1f7;
  --psr-disabled-color: #b9b9c8; // POSIBLE DARK: #797985, LIGHT: #dcdce3
  --psr-text-color: #ffffff;
  --psr-sombra: #dce8ef;
  --psr-secondary-background: #666666;
  --psr-input-border: #4d4d4d;
  --psr-input-border-29: #4d4d4d29;
  --psr-input-border-62: #4d4d4d9e;
  --psr-input-border-70: #4d4d4d70;
  --psr-shadow-box: #4d4d4d70;

  --psr-danger: #f7a7a7;
  --psr-danger-variation: #e29393;
  --psr-equis: #ff9fa2;
  --psr-naranja: #ff9f7f;
  --psr-suspended: #f38d7d; // POSIBLE #a8240f
  --psr-suspended-box: #b6441e;

  --psr-color-light-style: #7e7689;
  --psr-color-light-style-text: #ffffff;

  --psr-color-dark-style: #dce8ef;
  --psr-color-dark-style-text: #604897;

  --psr-color-background-box: rgba(64, 64, 81, 0.5);
  --psr-text-light: #404051;
  --psr-background-number: #f2f5fa; // FALTA

  --psr-background-opacity: rgba(0, 0, 0, 0.32);

  --psr-color-warning: #fcf8e3; // FALTA
  --psr-border-warning: #faebcc; // FALTA
}

.ios body.dark-theme {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

.ios ion-modal.dark-theme {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

.md body.dark-theme {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}

.grid {
  display: grid !important;
}

.justify-center {
  justify-items: center;
}

.grid-repeat-resposive {
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
}

.grid-repeat-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-repeat-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-repeat-6 {
  grid-template-columns: repeat(6, 1fr);
}

.span-2 {
  grid-column: span 2;
}
.span-3 {
  grid-column: span 3;
}

.span-4 {
  grid-column: span 4;
}

.gap-8px {
  gap: 8px !important;
}

/* Dealing with 2 orphan items */

.li-grid:last-child:nth-child(3n - 1) {
  grid-column-end: -3;
}

.li-grid:nth-last-child(2):nth-child(3n + 1) {
  grid-column-end: 2;
}

/* Dealing with single orphan */

.li-grid:last-child:nth-child(3n - 2) {
  grid-column-end: 3;
}

// ----------------------- COLORS ------------------------

.white {
  color: white !important;
}
.color-psr-alert-warning {
  color: var(--psr-alert-warning) !important;
}
.color-psr-app-aside-nav-head-bg {
  color: var(--psr-app-aside-nav-head-bg) !important;
}
.color-psr-background-cell {
  color: var(--psr-background-cell) !important;
}
.color-psr-background-empty {
  color: var(--psr-background-empty) !important;
}
.color-psr-background-material {
  color: var(--psr-background-material) !important;
}
.color-psr-backgroung-warning {
  color: var(--psr-backgroung-warning) !important;
}
.color-psr-bbb {
  color: var(--psr-bbb) !important;
}
.text-purple-ligh {
  color: var(--text-purple-light) !important;
}
.color-psr-border-warning {
  color: var(--psr-border-warning) !important;
}
.color-psr-box-value {
  color: var(--psr-box-value) !important;
}
.color-psr-btn {
  color: var(--psr-btn) !important;
}
.color-psr-btn-tab {
  color: var(--psr-btn-tab) !important;
}
.color-psr-ccc {
  color: var(--psr-ccc) !important;
}
.color-psr-checkbox-border {
  color: var(--psr-checkbox-border) !important;
}
.color-psr-check-view {
  color: var(--psr-check-view) !important;
}
.color-psr-checks {
  color: var(--psr-color-checks) !important;
}
.color-psr-cyan-dark {
  color: var(--psr-cyan-dark) !important;
}
.color-psr-cyan-variation {
  color: var(--psr-cyan-variation) !important;
}
.color-psr-danger {
  color: var(--psr-danger) !important;
}
.color-psr-danger-variation {
  color: var(--psr-danger-variation) !important;
}
.color-psr-dark {
  color: var(--psr-dark) !important;
}
.color-psr-disabled-color {
  color: var(--psr-disabled-color) !important;
}
.color-psr-empty {
  color: var(--psr-empty) !important;
}
.color-psr-equis {
  color: var(--psr-equis) !important;
}
.color-psr-font-color-primary {
  color: var(--psr-font-color-primary) !important;
}
.color-psr-font-success {
  color: var(--psr-font-success) !important;
}
.color-psr-input-border {
  color: var(--psr-input-border) !important;
}
.color-psr-input-group-hover {
  color: var(--psr-input-group-hover) !important;
}
.color-psr-light {
  color: var(--psr-light) !important;
}
.color-psr-light-gray {
  color: var(--psr-light-gray) !important;
}
.color-psr-light-green {
  color: var(--psr-light-green) !important;
}
.color-psr-green-text {
  color: var(--psr-green-text) !important;
}

.color-psr-naranja {
  color: var(--psr-naranja) !important;
}
.color-psr-suspended {
  color: var(--psr-suspended) !important;
}
.color-psr-suspended-box {
  color: var(--psr-suspended-box) !important;
}
.color-psr-mesage {
  color: var(--psr-mesage) !important;
}
.color-psr-positiveText {
  color: var(--psr-positiveText) !important;
}
.color-psr-pref-box {
  color: var(--psr-pref-box) !important;
}
.color-psr-primary {
  color: var(--psr-primary) !important;
}
.color-psr-primary-light-20 {
  color: var(--psr-primary-light-20) !important;
}
.color-psr-primary-background {
  color: var(--psr-primary-background) !important;
}
.color-psr-primary-light {
  color: var(--psr-primary-light) !important;
}
.color-psr-progress-bar-green {
  color: var(--psr-progress-bar-green) !important;
}
.color-psr-radio-button-border {
  color: var(--psr-radio-button-border) !important;
}
.color-psr-radio-button-bg {
  color: var(--psr-radio-button-bg) !important;
}
.color-psr-radio-button-bg-87 {
  color: var(--psr-radio-button-bg-87) !important;
}
.color-psr-rating {
  color: var(--psr-rating) !important;
}
.color-psr-sec-back-dark {
  color: var(--psr-sec-back-dark) !important;
}
.color-psr-secondary {
  color: var(--psr-secondary) !important;
}
.color-psr-secondary-background {
  color: var(--psr-secondary-background) !important;
}
.color-psr-selected-seat {
  color: var(--psr-selected-seat) !important;
}
.color-psr-sombra {
  color: var(--psr-sombra) !important;
}
.color-psr-shadow-box {
  color: var(--psr-shadow-box) !important;
}
.color-psr-star-yellow {
  color: var(--psr-star-yellow) !important;
}
.color-psr-text-link-pur {
  color: var(--psr-text-link-pur) !important;
}
.color-psr-text-modal-succ {
  color: var(--psr-text-modal-succ) !important;
}
.color-psr-text-muted {
  color: var(--psr-text-muted) !important;
}
.color-psr-text-subtitle {
  color: var(--psr-text-subtitle) !important;
}
.color-psr-text-title-modal {
  color: var(--psr-text-title-modal) !important;
}
.color-psr-text-color {
  color: var(--psr-text-color) !important;
}
.color-psr-text-color-55 {
  color: var(--psr-text-color-55) !important;
}
.color-psr-text-light-style {
  color: var(--psr-color-light-style-text) !important;
}
.color-psr-text-dark-style {
  color: var(--psr-color-dark-style-text) !important;
}
.color-psr-yellow {
  color: var(--psr-yellow) !important;
}
.color-psr-whatsapp-green {
  color: var(--psr-whatsapp-green) !important;
}
.color-psr-link {
  color: var(--psr-link) !important;
}
.psr-bg-box {
  --background: var(--psr-app-aside-nav-head-bg) !important;
  background: var(--psr-app-aside-nav-head-bg) !important;
}

// --------------------------- BACKGROUNDS ------------------------------

.background-input-border {
  background-color: var(--psr-input-border) !important;
}
.background-app-aside-nav-head-bg {
  background-color: var(--psr-app-aside-nav-head-bg) !important;
}
.background-camera-opac {
  background-color: var(--psr-camera-opac) !important;
}
.background-ccc {
  background-color: var(--psr-ccc) !important;
}
.background-bg-material {
  background-color: var(--psr-background-material) !important;
}
.background-bg-checks {
  background-color: var(--psr-bg-checks) !important;
}
.background-box-value {
  background-color: var(--psr-box-value) !important;
}
.background-btn-tab {
  background-color: var(--psr-btn-tab) !important;
}
.background-disabled-color {
  background-color: var(--psr-disabled-color) !important;
}
.background-light-gray {
  background-color: var(--psr-light-gray) !important;
}
.background-light-green {
  background-color: var(--psr-light-green) !important;
}
.background-message {
  background-color: var(--psr-mesage) !important;
}
.background-secondary {
  background-color: var(--psr-secondary-background) !important;
}
.background-selected-seat {
  background-color: var(--psr-selected-seat) !important;
}
.background-suspended-box {
  background-color: var(--psr-suspended-box) !important;
}
.background-empty {
  background-color: var(--psr-background-empty) !important;
}
.background-toggle-bg {
  background-color: var(--psr-toggle-bg) !important;
}
.background-pref-box {
  background-color: var(--psr-pref-box) !important;
}
.background-star-yellow {
  background-color: var(--psr-star-yellow) !important;
}
.background-yellow {
  background-color: var(--psr-yellow) !important;
}
.background-whatsapp-green {
  background-color: var(--psr-whatsapp-green) !important;
}
.background-white {
  background-color: white !important;
}
.background-transparent {
  background-color: transparent !important;
  --background: transparent !important;
}
.background-purple {
  background-color: var(--psr-radio-button-bg);
}
.background-purple-ionic {
  --background: var(--psr-radio-button-bg);
}
.background-purple-tabs {
  background-color: var(--psr-purple-tabs) !important;
}
.background-light-purple {
  background-color: var(--psr-light-purple) !important;
}
.background-psr-link {
  background: var(--psr-link) !important;
}
.background-psr-link-17 {
  background: var(--psr-link-17) !important;
}
.background-psr-green-text {
  background-color: var(--psr-green-text) !important;
}
.background-number {
  --background: var(--psr-background-number) !important;
  background-color: var(--psr-background-number) !important;
}

.background-box {
  --background: var(--psr-color-background-box) !important;
  background-color: var(--psr-color-background-box) !important;
}

.background-light-style {
  --background: var(--psr-color-light-style) !important;
  background-color: var(--psr-color-light-style) !important;
}

.background-dark-style {
  --background: var(--psr-color-dark-style) !important;
  background-color: var(--psr-color-dark-style) !important;
}

.bg-orange {
  --background: var(--border-warning) !important;
  background-color: var(--border-warning) !important;
}

.bg-warning {
  --background: var(--bg-warning) !important;
  background-color: var(--bg-warning) !important;
}

.bg-shadow-opacity {
  --background: var(--bg-shadow-opacity) !important;
  background-color: var(--bg-shadow-opacity) !important;
}
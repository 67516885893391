// Text-alignment
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-justify {
  text-align: justify !important;
}

// Text-transform
.capitalize {
  text-transform: capitalize !important;
}
.lowercase {
  text-transform: lowercase !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.transform-none {
  text-transform: none !important;
}

// Position
.absolute {
  position: absolute !important;
}
.relative {
  position: relative !important;
}
.fixed {
  position: fixed !important;
}

// Float
.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.float-center {
  float: center !important;
}

//weigths
.fw-400 {
  font-weight: 400 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.fw-bold {
  font-weight: bold !important;
}
.fw-normal {
  font-weight: normal !important;
}

// Size
.f9 {
  font-size: 0.5625em !important;
}
.f10 {
  font-size: 0.625em !important;
}
.f11 {
  font-size: 0.6875em !important;
}
.f12 {
  font-size: 0.75em !important;
}
.f13 {
  font-size: 0.8125em !important;
}
.f14 {
  font-size: 0.875em !important;
}
.f15 {
  font-size: 0.9375em !important;
}
.f16 {
  font-size: 1em !important;
}
.f17 {
  font-size: 1.0625em !important;
}
.f18 {
  font-size: 1.125em !important;
}
.f19 {
  font-size: 1.1875em !important;
}
.f20 {
  font-size: 1.25em !important;
}

.f24 {
  font-size: 1.5em !important;
}
.f25 {
  font-size: 1.5625em !important;
}
.f28 {
  font-size: 1.75em !important;
}
.f30 {
  font-size: 1.875em !important;
}
.f40 {
  font-size: 2.5em !important;
}
.f60 {
  font-size: 3.75em !important;
}

// OTHER
.fs-italic {
  font-style: italic !important;
}
.n-list-style {
  list-style: none !important;
}

.text-underline {
  text-decoration: underline !important;
}
.n-decoration {
  text-decoration: none !important;
}

.space-normal {
  white-space: normal !important;
}
.space-nowrap {
  white-space: nowrap !important;
}
.space-pre {
  white-space: pre !important;
}

.pointer {
  cursor: pointer !important;
}

.l-heigth-25 {
  line-height: 1.5625em;
}

// ------------------------ OVERFLOW -------------------------

.no-overflow {
  overflow: hidden !important;
}
.no-overflow-y {
  overflow-y: hidden !important;
}
.no-overflow-x {
  overflow-x: hidden !important;
}

.overflow-y {
  overflow-y: auto !important;
}
.overflow-x {
  overflow-x: auto !important;
}

// ------------------------ ALIGN CONTAINER --------------------------

.filter-form,
.filter-form-col,
.filter-form-col-2box,
.filter-form-col-2,
.filter-form-col-3,
.filter-form-col-4,
.filter-form-col-box {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  grid-template-columns: repeat(1, 1fr);
  .filter-item {
    padding: 8px 8px 0 0;
  }
  .span-filter-to-4 {
    grid-column: span 1;
  }
}

.search-filter {
  .filter-item {
    width: 100%;
    @media screen and (min-width: 480px) {
      width: auto;
    }
  }
}

.item-margin {
  margin: 0px;
}
.item-option {
  text-align: center;
}

@media (min-width: 480px) {
  .filter-form-col {
    grid-template-columns: repeat(2, 1fr);
  }
  .filter-form-col-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .filter-form-col-3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .filter-form-col-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {
  .filter-form {
    grid-template-columns: repeat(2, 1fr);
  }
  .span-filter-to-4 {
    grid-column: span 2 !important;
  }
}

@media (min-width: 768px) {
  .filter-form-col-2box {
    grid-template-columns: repeat(2, 1fr);
  }
  .filter-form-col {
    grid-template-columns: repeat(3, 1fr);
  }
  .filter-form-col-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .filter-form-col-4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .filter-form-col {
    grid-template-columns: repeat(2, 1fr);
  }
  .filter-form {
    grid-template-columns: repeat(3, 1fr);
  }
  .span-filter-to-4 {
    grid-column: span 3 !important;
  }
  .item-margin {
    margin-right: 30px !important;
  }
  .item-option {
    text-align: left !important;
  }
}

@media (min-width: 1080px) {
  .filter-form-col-box {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1240px) {
  .filter-form {
    grid-template-columns: repeat(4, 1fr);
  }
  .span-filter-to-4 {
    grid-column: span 4 !important;
  }
}

@media (min-width: 1440px) {
  .filter-form-col-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.top--15 {
  top: -15px !important;
}
.top--32 {
  top: -32px !important;
}

.top-0 {
  top: 0px !important;
}
.left-0 {
  left: 0px !important;
}
.bottom-16 {
  bottom: 16px !important;
}
.right-16 {
  right: 16px !important;
}

.top-22 {
  top: 22px !important;
}
.right-22 {
  right: 22px !important;
}

.top-26 {
  top: 26px !important;
}
.top-60 {
  top: 60px !important;
}
.top-m-14 {
  top: -14px !important;
}
.right-32 {
  right: 32px !important;
}

.z-index-10 {
  z-index: 10;
}

.n-pointer-event {
  pointer-events: none !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.n-allowed {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.text-ellipsis {
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-container {
  background-color: var(--psr-font-color-primary);
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 3px 6px var(--psr-light);
}

.menu-copny-header {
  max-width: 360px !important;
  min-width: 360px !important;
  box-shadow: 0px 8px 10px var(--psr-shadow-box) !important;
  @media screen and (max-width: 800px) {
    min-width: auto !important;
    max-width: 230px !important;
  }
}

.passer-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.passer-phone-input {
  &__code {
    width: 44.5%;
  }
  &__separate {
    width: 1%;
    height: 56px;
  }
  &__phone {
    width: 54.5%;
  }
}

.image-cropp {
  max-height: 50vh !important;
  .ngx-ic-overlay {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
}

.n-resize {
  resize: none !important;
}

.ngx-pagination {
  padding-left: 0 !important;
}

.label-as-material-input {
  @extend .radius-t-4;
  @extend .background-bg-material;
  @extend .pt-10;
  @extend .pb-8;
  @extend .px-16;
  &__title {
    @extend .f11;
    @extend .text-ellipsis;
    @extend .fw-normal;
    @extend .color-psr-radio-button-bg;
    @extend .transform-none;
    @extend .m-0;
    @extend .mb-2;
  }
  &__description {
    @extend .f13;
    @extend .m-0;
    @extend .text-ellipsis;
  }
}

.box-warning {
  background-color: var(--psr-color-warning);
  border: 1px solid var(--psr-border-warning);
  @extend .p-8;
  @extend .radius-5;
}

.special-table {
  &__tbody {
    &:nth-of-type(even) {
      background-color: var(--psr-secondary-background);
    }
    &:hover {
      background-color: var(--psr-box-value);
    }
  }
}

// Slide
.slide-in, .slide-out { 
  position: absolute;
  left: calc(50% - 250px);
  transition: left 0.5s;
  -webkit-transition: left 0.5s;
  -moz-transition: left 0.5s;
  -ms-transition: left 0.5s;
  -o-transition: left 0.5s;
}

.slide-out {
  left: 100vw;
}
.radio-passer,
.radio-passer-5,
.radio-passer-white,
.radio-passer-border {
  box-shadow: 3px 4px 5px -1px rgba(0, 0, 0, 0.3);
  --border-width: 0px;
  border-radius: 50%;
  background-color: var(--psr-font-color-primary);
  .radio-inner {
    width: 7px !important;
    height: 7px !important;
  }
  --color-checked: var(--psr-radio-button-bg);
}

.radio-passer-border {
  border: 2px solid var(--psr-disabled-color);
  box-shadow: none;
}

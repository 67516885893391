// ------------------------ RADIUS -----------------------

.n-radius {
  border-radius: 0px !important;
}
.radius-50 {
  border-radius: 50% !important;
}
.radius-2 {
  border-radius: 2px !important;
}
.radius-4 {
  border-radius: 4px !important;
}
.radius-5 {
  border-radius: 5px !important;
}
.radius-8 {
  border-radius: 8px !important;
}
.radius-12 {
  border-radius: 12px !important;
}
.radius-16 {
  border-radius: 16px !important;
}
.radius-25 {
  border-radius: 25px !important;
}
.radius-100 {
  border-radius: 100px !important;
}

.radius-t-4 {
  border-radius: 4px 4px 0px 0px !important;
}

// ------------------------ BORDER ------------------------
.border-transparent {
  border: 1px solid transparent;
}
.border-blue {
  border: 1px solid var(--psr-check-view) !important;
}
.border-blue-hover {
  &:hover {
    border: 1px solid var(--psr-check-view) !important;
  }
}
.border-DB {
  border: 1px solid var(--psr-input-border) !important;
}
.border-t {
  border-top: 1px solid var(--psr-input-border) !important;
}
.border-b {
  border-bottom: 1px solid var(--psr-input-border) !important;
}
.border-r {
  border-right: 1px solid var(--psr-input-border) !important;
}
.border-l {
  border-left: 1px solid var(--psr-input-border) !important;
}
.n-border {
  border: none !important;
}
.n-t-border {
  border-top: none !important;
}
.n-r-border {
  border-right: none !important;
}
.n-b-border {
  border-bottom: none !important;
}
.n-l-border {
  border-left: none !important;
}

.item-n-border {
  --inner-border-width: 0px !important;
  --border-width: 0px;
}

// ------------------------ SHADOW ---------------------------

.n-shadow {
  box-shadow: none !important;
}
.shadow-nav {
  box-shadow: 3px 6px 6px var(--psr-shadow-box) !important;
}
.shadow-box {
  box-shadow: 0px 3px 6px var(--psr-light) !important;
}

// ------------------------ divider line ----------------------

.divider,
.dividerCCC {
  height: 1px;
  overflow: hidden;
}
.divider {
  border: 1px solid var(--psr-input-border);
}
.dividerCCC {
  border: 1px solid var(--psr-ccc);
}

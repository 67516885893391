.flex {
  display: flex !important;
}
.block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-inline-table {
  display: inline-table !important;
}
.inline-block {
  display: inline-block !important;
}
.inline-flex {
  display: inline-flex !important;
}
.inline-grid {
  display: inline-grid !important;
}
.d-initial {
  display: initial !important;
}
.d-none {
  display: none !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.n-pointer {
  pointer-events: none !important;
}
.pointer-all {
  pointer-events: all !important;
}
.v-hide {
  visibility: hidden !important;
  height: 0px !important;
  width: 0px !important;
}

.v-middle {
  vertical-align: middle !important;
}
.v-text-bottom {
  vertical-align: text-bottom !important;
}
.overflow-y {
  overflow-y: auto !important;
}
.overflow-none {
  --overflow: hidden !important;
  overflow: hidden !important;
}

.flex-column-nowrap {
  flex-flow: column nowrap !important;
}

.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}

.d-block {
  display: block !important;
}

.sticky {
  position: sticky !important;
}

.z-index-1000 {
  z-index: 1000 !important;
}

.p-absolute {
  position: absolute !important;
}

.flex-g-1 {
  flex-grow: 1;
}

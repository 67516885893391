.mdc-tab {
  font-size: 1em;
}
mat-tab-header {
  border: none;
}
.mdc-tab__text-label {
  font-size: 0.6875em;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--psr-text-color);
  text-align: center;
}

.mat-form-field-underline,
.mdc-line-ripple,
.mat-pseudo-checkbox,
.mat-mdc-form-field-subscript-wrapper{
  display: none !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0px;
}

mat-label,  
.mat-mdc-select-min-line,
.mdc-list-item__primary-text,
.mdc-text-field__input{
  font-size: 0.8125em !important;
}

.mat-form-field, .calendar-range {
  .mat-form-field-infix{
    width: 100%;
    input, .mat-form-field-label, .mat-select-value-text{
      font-size: 0.8125em;
    }
  }
}

.mat-form-field-flex {
  display: grid !important;
  .mat-form-field-flex {
    padding: 8px 12px !important;
    .mat-form-field-infix {
      display: flex !important;
      align-items: center !important;
      border-top: none !important;
      padding: 0px !important;
      .form-field-span {
        line-height: 42px;
        background: var(--psr-ccc);
        width: 1px;
      }
    }
  }
}

.mat-mdc-form-field-infix {
  width: auto !important;
}

.mat-form-field.no-label{
  .mat-form-field-infix{
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
  .mat-select-arrow-wrapper{
    height: 0px;
  }
}

.mat-form-field.flex {
  display: inline-flex !important;
  .mat-form-field-wrapper {
    width: 100% !important;
  }
  .mat-form-field-flex {
    display: flex !important;
  }
}

.no-border-top-left {
  .mat-form-field-flex {
    border-top-left-radius: 0px !important;
  }
}

.no-border-top-right {
  .mat-form-field-flex {
    border-top-right-radius: 0px !important;
  }
}

.datepicker .mat-form-field-flex,
.calendar-range .mat-form-field-flex {
  display: inline-flex !important;
  align-items: center !important;
}

.mat-mdc-select-value {
  display: grid !important;
}

.calendar-range {
  .mat-form-field-flex {
    .mat-date-range-input-start-wrapper {
      input {
        top: 3px;
      }
    }
    .mat-date-range-input-separator {
      margin-right: 8px;
    }
  }
}

.no-border {
  .mat-form-field-infix {
    border: none !important;
  }
  .mat-select-arrow-wrapper {
    transform: none !important;
  }
}

// autocomplete {
.mat-mdc-autocomplete-panel {
  .mdc-list-item__primary-text {
    width: 100%;
    padding: 8px 0;
  }
}

// radio-button
.mat-radio-button {
  .mat-radio-label-content {
    display: flex;
    align-items: center;
  }
  .mat-radio-label {
    white-space: normal;
  }
}
.mat-radio-button.f13,
.mat-checkbox.f13 {
  .mat-radio-label-content,
  .mat-checkbox-label {
    font-size: 13px !important;
  }
}

.mat-radio-button.fw-bold {
  .mat-radio-label-content {
    font-weight: bold !important;
  }
}
.mat-radio-button.purple11 {
  .mat-radio-label-content {
    font-weight: bold !important;
    color: var(--psr-radio-button-bg) !important;
    text-transform: uppercase !important;
    font-size: 0.75em !important;
  }
}

// stepper
.mat-stepper-horizontal {
  .mat-stepper-horizontal-line {
    max-width: 120px;
  }
  .mat-step-icon-selected,
  .mat-step-icon-state-done {
    background-color: var(--psr-light-purple) !important;
  }
}

.mat-stepper-horizontal.p-0 {
  .mat-horizontal-content-container {
    padding: 0px;
  }
}

// slider-toggle

.psr-slider-toggle {
  font-size: 13px !important;
  .mdc-switch__handle::before{
    background-color: var(--bg-light) !important;
   }
  .mdc-switch__handle::after{
    background-color: var(--bg-light) !important;
  }
}

.psr-slider-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--psr-radio-button-bg) !important;
}

.psr-slider-toggle,
.psr-slider-toggle .mat-slide-toggle-content,
.mat-radio-button.fw-normal .mat-radio-label-content,
.mat-checkbox.fw-normal .mat-checkbox-label {
  font-weight: normal;
}
.mat-checkbox.break-spaces {
  .mat-checkbox-label {
    white-space: break-spaces;
  }
}

// ------------------------- SNACKBAR ------------------------

.mat-mdc-snack-bar-container {
  background: var(--psr-dark);
  color: var(--psr-font-color-primary);
  max-width: 600px !important;
  margin: 24px auto !important;
}

// -------------------------- MENU ----------------------------

.menu-filter {
  max-width: 100vw !important;
  @media screen and (min-width: 480px) {
    max-width: max-content !important;
  }
}

.menu-filter.transaction {
  max-height: 300px;
  width: 80vw;

  @media screen and (min-width: 992px) {
    max-height: none !important;
    max-width: 670px !important;
    width: 670px;
  }
}

// ------------------------ TIMEPICKER ------------------------

.timepicker-backdrop-overlay, .timepicker-overlay{
  z-index: 1000 !important;
}

.timepicker-overlay {
  .timepicker {
    height: auto;
    width: auto;
    background: transparent;
    border: none;
    padding: 0px;
    &__header {
      background-color: var(--psr-radio-button-bg) !important;
      .timepicker-dial {
        &__container {
          justify-content: center !important;
        }
      }
    }
    &__actions {
      .timepicker-button {
        color: var(--psr-radio-button-bg) !important;
      }
    }
    &__body {
      .clock-face {
        &__clock-hand,
        &__number > span.active {
          background-color: var(--psr-radio-button-bg) !important;
        }
      }
    }
  }
}
